import { useEffect, useRef, useState } from "react";
import "./App.css";
import logo from "./logo.svg";

const DEFAULT_PARAGRAPHS = 4;

const constructUrl = (baseUrl, paragraphs, author) => {
  if (!paragraphs && !author) {
    return baseUrl;
  }
  if (paragraphs && author) {
    return `${baseUrl}?quantity=${paragraphs}&author=${encodeURIComponent(
      author
    )}`;
  }
  if (paragraphs) {
    return `${baseUrl}?quantity=${paragraphs}`;
  }
  if (author) {
    return `${baseUrl}?author=${encodeURIComponent(author)}`;
  }
};

const copyToClipboard = (ref) => {
  const str = ref.current.innerText;
  const el = document.createElement("textarea");
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

function Alert({ content, author }) {
  return (
    <section className="App-alert">
      <div className="App-icon">
        <svg
          className="icon icon--no-title"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
        </svg>
      </div>
      <div className="App-alert-wrapper">
        <div className="App-alert-text-wrapper">
          <p className="App-alert-content">
            {" "}
            {content.length
              ? `There are only ${content.length} quotes by ${author}`
              : "There are no quotes available"}
          </p>
        </div>
      </div>
    </section>
  );
}

function App() {
  const [paragraphs, setParagraphs] = useState(DEFAULT_PARAGRAPHS);
  const [author, setAuthor] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [content, setContent] = useState([]);
  const [availableAuthors, setAvailableAuthors] = useState([]);
  const [loading, setLoading] = useState(!content);
  const [displayAuthorAndSource, setDisplayAuthorAndSource] = useState(false);
  const [formValues, setFormValues] = useState({
    paragraphs,
    author: "",
    displayAuthorAndSource: false,
  });
  const ref = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    setParagraphs(formValues.paragraphs);
    setAuthor(formValues.author);
    setDisplayAuthorAndSource(formValues.displayAuthorAndSource);
  };

  const handleChange = (e) => {
    const {
      target: { value, id },
    } = e;
    setFormValues({ ...formValues, [id]: value });
    if (e.target.type === "checkbox") {
      const {
        target: { checked, id },
      } = e;
      setFormValues({ ...formValues, [id]: checked });
    }
  };

  const baseUrl = "https://still-hamlet-75391.herokuapp.com/paragraphs";
  const authorsUrl = "https://still-hamlet-75391.herokuapp.com/authors";

  useEffect(() => {
    const url = constructUrl(baseUrl, paragraphs, author);
    setLoading(true);

    fetch(url)
      .then((response) => response.json())
      .then((results) => setContent(results))
      .catch((e) => setError(e));

    fetch(authorsUrl)
      .then((response) => response.json())
      .then((results) => setAvailableAuthors(results))
      .catch((e) => setError(e));

    setLoading(false);
  }, [paragraphs, author]);

  return (
    <div className="App">
      <header className="App-header">
        <h1>Socialipsum</h1>
        <h2>Placeholder texts by the people and for the people</h2>
      </header>

      {loading || !content ? (
        <img src={logo} className="App-logo" alt="logo" />
      ) : (
        <>
          {paragraphs > content.length && (
            <Alert content={content} author={author} />
          )}
          <div className="App-content" ref={ref}>
            {content.map((item) => {
              const paragraph = (
                <p>
                  {item.text}
                  {displayAuthorAndSource && (
                    <>
                      <br />
                      <b>{item.author}</b> - <i>{item.sourceName}</i>
                    </>
                  )}
                </p>
              );
              return paragraph;
            })}
          </div>
          <div className="form-container">
            <form className="App-form" onSubmit={handleSubmit}>
              <input
                name="paragraphs"
                id="paragraphs"
                type="number"
                onChange={handleChange}
                value={formValues.paragraphs}
              />
              <label htmlFor="paragraphs">paragraphs </label>

              <label htmlFor="author"> by </label>
              <select
                name="author"
                id="author"
                value={formValues.author}
                onChange={handleChange}
              >
                <option value={""}>Any author</option>
                {availableAuthors.map((author) => (
                  <option value={author}>{author}</option>
                ))}
              </select>
              <label htmlFor="displayAuthorAndSource">
                Show author and source{" "}
              </label>
              <input
                id="displayAuthorAndSource"
                type="checkbox"
                onChange={handleChange}
              ></input>
              <button type="submit">Generate</button>
            </form>
            <button onClick={() => copyToClipboard(ref)}>Copy</button>
          </div>
        </>
      )}
      <footer>
        Created by{" "}
        <a
          href="https://virginiabalseiro.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Virginia Balseiro
        </a>{" "}
        | Texts are taken from{" "}
        <a
          href="https://www.marxists.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          marxists.org
        </a>
      </footer>
    </div>
  );
}

export default App;
